<template>
  <div class="list">
    <Header :now="3" ref="header" />
    <div class="content">
      <div class="part1" id="part1">01.赛事时刻表</div>
      <div class="progress-bar" :style="{ backgroundImage: `url(${statusImg})` }"></div>
      <div class="part-list">02.互动专区</div>
      <div class="more-box">
        <div class="filter-tab">
          <div v-for="(item, index) in role_name" :key="item.id" :class="type_info == index ? 'active' : ''"
            @click="onFilterRole(item.id, index)" class="filter-item">
            {{ item.name }}
          </div>
        </div>
        <div class="more-box-right">
          <span @click="onShowPop(1)" class="vote">我的票数：{{ vote }}</span><a href="javascript:;" class="more"
            @click="onShowPop(2)">小杀酒社有奖投票<span class="ic ic-arrow-right"></span></a>
        </div>
      </div>

      <div class="no-data" v-if="!haveData">
        <div>
          <div>目前还没有作品上榜哦，快去参赛吧~</div>
          <a href="javascript:;" class="ic-btn btn-in" @click="onJumpForm(1)"></a>
        </div>
      </div>
      <div class="content-border" v-else>
        <div class="rank-search">
          <div class="filter-rank">
            <div :class="sort == 1 ? 'active' : ''" @click="onFilterSort(1)" class="rank-item">
              热度排序<span class="ic arrow"></span>
            </div>
            <div :class="sort == 2 ? 'active' : ''" @click="onFilterSort(2)" class="rank-item">
              时间排序<span class="ic arrow"></span>
            </div>
            <div :class="sort == 3 ? 'active' : ''" @click="onFilterSort(3)" class="rank-item">
              随机排序<span class="ic arrow"></span>
            </div>
          </div>
          <div class="search">
            <input type="text" placeholder="请输入设计师昵称" v-model="keyword" @input="keyInput($event)"
              v-on:keyup.13="onFilterSearch" class="search-input" />
            <button type="button" class="btn-search" @click="onFilterSearch">
              <div class="search-icon"></div>
            </button>
          </div>
        </div>
        <div class="in-line"></div>
        <div class="list-border">
          <div class="list-r" v-if="list.length > 0">
            <div :class="item.type == 4 ? 'jin-item' : 'list-item'" v-for="(item, index) in list" :key="index"
              @click="onJumpDetail(item.wid)">
              <div class="top">
                <div class="name">{{ item.author }}</div>
              </div>

              <div class="role">
                <div class="role-name">
                  <div class="country">
                    {{
                      item.type == 4
                        ? "锦"
                        : role_country.get(item.role_country)
                    }}
                  </div>
                  <div class="name">
                    {{ item.type == 4 ? "锦囊 · 望梅止渴" : item.role_name }}
                  </div>
                  <div class="blood">
                    <div v-if="item.type !== 4" class="physical">
                      <img src="~/assets/images/star_on.png" v-for="(flood, index) in item.blood" :key="index"
                        class="xue" />
                    </div>

                  </div>
                </div>
                <div class="skill">
                  <div class="skill-item">
                    <div class="skill-name" v-if="item.type !== 4">【{{ item.key1 }}】</div>


                    <div class="skill-cont">{{ item.val1 }}</div>
                  </div>
                  <div class="skill-item" v-if="item.key2">
                    <div class="skill-name">【{{ item.key2 }}】</div>
                    <div class="skill-cont">{{ item.val2 }}</div>
                  </div>
                  <div class="skill-item" v-if="item.key3">
                    <div class="skill-name">【{{ item.key3 }}】</div>
                    <div class="skill-cont">{{ item.val3 }}</div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="ics">
                    <a href="javascript:;" class="ic ic-zan" :class="item.vote_status == 1 ? 'ic-zaned' : ''"></a>{{
                      item.tickets > 9999 ? "9999+" : item.tickets }}
                  </div>
                  <div class="ics">
                    <a href="javascript:;" class="ic ic-pinlun"></a>{{ item.commit > 9999 ? "9999+" : item.commit }}
                  </div>
                  <div class="ics">
                    <a href="javascript:;" class="ic ic-fenxiang"></a>{{ item.shares > 99 ? "99+" : item.shares }}
                  </div>
                  <div class="icons">
                    <div class="ic ic-hot" v-if="item.daily_hot > 0"></div>
                    <div class="ic ic-arrow-up" v-if="item.daily_up > 0"></div>
                    <div class="ic ic-arrow-down" v-if="item.daily_down > 0"></div>
                    <div class="ic ic-fire" v-if="item.daily_share > 0"></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 由父组件的pageparm单项绑定，更新子数据,callFather是子组件中参数变化触发的 -->
            <Page :total="pageTotal" :size="pageSize" :page="page" :changge="pageFn" v-if="pageTotal > 9"></Page>
          </div>
          <div class="no-data-text" v-else>没有数据</div>
        </div>
      </div>
    </div>
    <Copyright v-show="!isChannel()" />
    <!-- 小杀酒社有奖投票 -->
    <Pop :visible="isShowPop" @hidePop="hidePop">
      <div class="pop">
        <div class="ic pop-top">
          小杀酒社有奖投票<a href="javascript:;" class="ic pop-close" @click="hidePop"></a>
        </div>
        <div class="pop-cont">
          <div class="code"><img src="~/assets/images/code3.png" /></div>
          <div class="text-cont">
            <p>参与小杀酒社互动任务可获得更多有奖票数</p>
            <p>在小杀酒社内投票达到对应数量，即刻解锁竞猜币等道具奖励~</p>
          </div>
          <div class="tips">详情规则参看小杀酒社内活动规则</div>
        </div>
      </div>
    </Pop>
    <!-- 我的票数 -->
    <Pop :visible="isShowPopVote" @hidePop="hidePop">
      <div class="pop">
        <div class="ic pop-top">
          我的票数<a href="javascript:;" class="ic pop-close" @click="hidePop"></a>
        </div>
        <div class="pop-cont">
          <h3 class="p-title">【图标含义如下】：</h3>
          <div class="cont-t icons">
            <div class="p-item">
              <span class="ic ic-hot"></span>作品当日获票+评论超过50个
            </div>
            <div class="p-item">
              <span class="ic ic-arrow-up"></span>当日排名上升超过20位
            </div>
            <div class="p-item">
              <span class="ic ic-arrow-down"></span>当日排名下降超过10位
            </div>
            <div class="p-item">
              <span class="ic ic-fire"></span>当日被分享超过10次
            </div>
          </div>
          <h3 class="p-title">【每位玩家初始票数为150票】：</h3>
          <div class="cont-t">
            <p>6月27日投稿&投票开启后，每位玩家可无门槛获得150票</p>
          </div>
          <div class="tips text-left">
            注：该投票计入作品人气热度，但不参与小杀酒社社区有奖活动哦
          </div>
        </div>
      </div>
    </Pop>
    <!-- 活动未开始弹框 -->
    <Pop-tips :visible="isShowPopTips" @hidePop="hidePop" />
    <!-- 加载中... -->
    <Loading :visible="loading" />
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "../components/content/header.vue";
import Pop from "../components/content/pop.vue";
import Loading from "@/components/common/Loading";
import Copyright from "@/components/content/Copyright";
import { judgeWeb, isChannel } from "@/common/util";
import ScrollBar from "@/components/common/ScrollBar";
import { reqRank, reqMyVote } from "@/api/http";
import { role_name } from "@/common/config";
import Page from "@/components/common/Page.vue";
import { role_country } from "@/common/config";
import PopTips from "@/components/content/PopTips";
import { config } from "@/common/const";
import step1 from "@/assets/images/step1.png";
import step2 from "@/assets/images/step2.png";
import step3 from "@/assets/images/step3.png";
import step4 from "@/assets/images/step4.png";
export default {
  name: "List",
  components: {
    Header,
    Page,
    Copyright,
    Pop,
    PopTips,
    Loading,
  },
  computed: {
    statusImg: function () {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      // 确保我们关注的是2024年
      if (year > 2024) {
        return step4;
      }
      const month = currentDate.getMonth() + 1; // getMonth() 返回0-11，所以需要+1
      const day = currentDate.getDate();
      console.log(year, month, day);
      if (month < 7) {
        return step1;
        // 在7月8日之前
      } else if (month === 7) {
        if (day < 8) {
          return step1;
        } else if (day < 13) {
          return step2;
        } else if (day < 19) {
          return step3;
        } else {
          return step4;
        }
      } else {
        return step4; // 7月19日及以后
      }
    },
  },
  data() {
    return {
      token: "",
      agent: "pc", //是手机访问还是电脑
      role_name, //角色列表
      loading: true, isChannel,
      type_info: 0,
      role_type: 0, //角色id
      sort: 1,
      keyword: "",
      page: 1, //当前页码
      pageSize: 6, //每页显示条目个数不传默认10
      pageTotal: 0, //总数
      list: [],
      vote: 0,
      isShowPop: false,
      isShowPopVote: false,
      haveData: false,

      isGoSubmit: false, //是否可投稿
      isShowPopTips: false, //开始投稿日期提示弹框
      timer: null, //定时器
      config,
      role_country,
    };
  },

  mounted() {
    //判断pc还是h5
    this.agent = 'pc';
    this.token = localStorage.getItem("token");
    if (this.token) {
      this.loadMyVote();
    }
    this.loadWorkList();
    //定时开启开赛日
    this.timer = setInterval(this.funRtime, 1000);
  },
  methods: {
    //倒计时活动开始日期
    funRtime() {
      var startTime = new Date(config.startTime);
      var NowTime = new Date();
      if (NowTime >= startTime) {
        //活动开始了
        console.log("开始了list");
        this.isGoSubmit = true;
        clearInterval(this.timer);
      } else {
        console.log("没开始list");
        this.isGoSubmit = false;
      }
    },
    //选择角色
    onFilterRole(id, index) {

      this.type_info = index
      if (index === 4) {
        this.role_type = 0;
        this.type = 4
      } else {
        this.role_type = id
        this.type = 0
      }
      this.page = 1;
      this.loadWorkList();
    },
    //选择热度
    onFilterSort(id) {
      this.sort = id;
      this.page = 1;
      this.loadWorkList();
    },
    // 得到搜索词
    keyInput(e) {
      this.keyword = $.trim(e.target.value);
      this.page = 1;
      if (!this.keyword) {
        this.loadWorkList();
      }
    },
    //搜索
    onFilterSearch() {
      this.list = [];
      this.page = 1;
      this.loadWorkList();
    },
    // 作品列表
    loadWorkList() {
      let { page, role_type, pageSize, sort, keyword, type } = this;
      reqRank({ page, page_size: pageSize, role_type, type, sort, author: keyword })
        .then((res) => {
          if (res.status === 0) {
            if (res.data.list.length > 0) {
              this.list = res.data.list;
              this.haveData = true;
            } else {
              this.list = [];
            }
            this.pageTotal = res.data.total_num;
          } else if (res.status == -1) {
            localStorage.clear();
            this.token = "";
            this.$refs.header.showLogin();
          } else {
            this.$toast.show(res.msg);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$toast.show("出错了");
          this.loading = false;
        });
    },
    //页码切换执行方法
    pageFn(val) {
      this.page = val;
      this.loadWorkList();
    },
    //跳转详情
    onJumpDetail(wid) {
      this.$router.push({ path: "/detail", query: { wid } });
    },
    //我的投票
    loadMyVote() {
      let { token } = this;
      reqMyVote({ token })
        .then((res) => {
          if (res.status === 0) {
            this.vote = res.data.vote;
          } else if (res.status == -1) {
            this.token = "";
            localStorage.clear();
            this.$refs.header.showLogin();
          } else {
            this.$toast.show(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.show("出错了");
        });
    },
    //跳转投稿
    onJumpForm(role) {
      if (this.isGoSubmit) {
        if (this.token) {
          localStorage.removeItem("workdetail");
          this.$router.push({ path: "/form", query: { role } });
        } else {
          this.$refs.header.showLogin();
        }
      } else {
        this.isShowPopTips = true;
      }
    },
    //显示弹框
    onShowPop(type) {
      if (type == 1) {
        this.isShowPopVote = true;
      } else {
        this.isShowPop = true;
      }
    },
    hidePop() {
      this.isShowPop = false;
      this.isShowPopVote = false;
      this.isShowPopTips = false;
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="less" scoped>
.pop-top {
  background-position: 0 0;
  border-right: 0.02rem solid #a38b5c;
  border-left: 0.02rem solid #a38b5c;
  background: url(../assets/images/popu-header.png) no-repeat;
  background-size: cover;

  .pop-close {
    left: calc(50% - 0.21rem);
    bottom: -5.2rem;
  }
}

.pop-cont {
  background-color: #faf8f0;

  .tips {
    color: #6e5a46;
  }
}

.filter-item.active {
  display: block;
  background: url(../assets/images/sprited.png) no-repeat;
  // background-size: 8rem auto;
  // font-size: 0;
}

.icons {
  .ic {
    width: 0.24rem;
    height: 0.24rem;
    margin: 0 0.03rem;
    display: block;

    background-size: 8rem auto;
    font-size: 0;
  }

  .ic-hot {
    width: 0.16rem;
    background: url(../assets/images/hot.png) no-repeat;
  }

  .ic-arrow-up {
    background: url(../assets/images/arrow-up.png) no-repeat;
  }

  .ic-arrow-down {
    background: url(../assets/images/arrow-down.png) no-repeat;
  }

  .ic-fire {
    background: url(../assets/images/fire.png) no-repeat;
  }
}

.ics {
  .ic-zan {
    width: 0.26rem !important;
    height: 0.22rem;
    background: url(../assets/images/heart.png) no-repeat;
    background-position: 0 0 !important;

    &:hover {
      background: url(../assets/images/sprites.png) no-repeat;
      background-position: -5.47rem -0.64rem !important;
    }
  }

  .ic-pinlun {
    width: 0.26rem !important;
    height: 0.22rem;
    background: url(../assets/images/comments.png) no-repeat;
    background-position: 0 0 !important;

    &:hover {
      background: url(../assets/images/sprites.png) no-repeat;
      background-position: -5.86rem -0.64rem !important;
    }
  }

  .ic-fenxiang {
    width: 0.26rem !important;
    height: 0.22rem;
    background: url(../assets/images/share.png) no-repeat;
    background-position: 0 0 !important;

    &:hover {
      background: url(../assets/images/sprites.png) no-repeat;
      background-position: -6.22rem -0.64rem !important;
    }
  }
}

.jin-item {
  display: flex;
  position: relative;
  width: 4.83rem;
  height: 2.86rem;
  background: url(../assets/images/jin-item.png) no-repeat left top;
  background-size: 4.83rem auto;
  cursor: pointer;
  margin-top: 0.32rem;

  .icons {
    display: flex;
    align-items: center;

    .ic {
      width: 0.24rem;
      height: 0.24rem;
      margin: 0 0.03rem;
    }

    // .ic-hot {
    //   width: 0.16rem;
    //   background-position: -5.14rem -0.03rem;
    // }

    // .ic-arrow-up {
    //   background-position: -5.35rem -0.03rem;
    // }

    // .ic-arrow-down {
    //   background-position: -5.65rem -0.04rem;
    // }

    // .ic-fire {
    //   background-position: -5.93rem -0.03rem;
    // }
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 100%;
    height: 1rem;
    writing-mode: vertical-rl;
    margin-top: 0.84rem;
    padding: 0 0.15rem;
    color: #416764;

    .name {
      // width: 1.6rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
    }
  }

  .role {
    height: 2.68rem;
    padding: 0 0.4rem 0 0.25rem;
    color: #000;
    margin-top: 0.11rem;

    .role-name {
      height: 0.42rem;
      display: flex;

      width: 3.63rem;
      margin-top: 0.18rem;
      align-items: center;

      font-size: 0.22rem;
      font-weight: bold;

      .name {
        flex-shrink: 1;
        margin-right: 0.1rem;
        margin-left: 0.1rem;
        color: #416764;
      }

      .blood {
        display: flex;
        justify-content: flex-end;
        // width: 1.5rem;
      }

      .xue {
        width: 0.2rem;
        height: 0.2rem;
        margin-left: 0.02rem;
        margin-top: 0.06rem;
      }
    }

    .country {
      width: 38px;
      height: 34px;
      background: url(../assets/images/alia-country.png) no-repeat;
      background-size: cover;

      font-family: FZSHENGSKS_ZHONGJW--GB1-0;
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 34px;
      text-align: center;
      letter-spacing: 0px;
      color: #dff6f8;
    }

    .skill {
      width: 100%;
      height: 1.34rem;
      overflow-y: auto;
      margin-top: 0.1rem;

      .skill-item {
        display: flex;
        color: #070707;
        margin-bottom: 0.1rem;

        .skill-name {
          width: 0.7rem;
          text-align: left;
        }

        .skill-cont {
          flex: 1;
          text-align: left;
          padding-right: 0.03rem;
        }
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      color: #4f868d;
      // justify-content: space-between;
      height: 0.61rem;
      // margin-top: 0.1rem;

      .ics {
        display: flex;
        margin-right: 0.35rem;
      }

      .ic-zan {
        width: 0.26rem !important;
        height: 0.22rem;
        background: url(../assets/images/heart2.png) no-repeat;
        background-position: 0 0 !important;
        margin-right: 0.05rem;

        &:hover {
          background: url(../assets/images/sprites.png) no-repeat;
          background-position: -5.47rem -0.64rem !important;
        }
      }

      .ic-pinlun {
        width: 0.26rem !important;
        height: 0.22rem;
        margin-right: 0.05rem;
        background: url(../assets/images/comments2.png) no-repeat;
        background-position: 0 0 !important;

        &:hover {
          background: url(../assets/images/sprites.png) no-repeat;
          background-position: -5.86rem -0.64rem !important;
        }
      }

      .ic-fenxiang {
        width: 0.26rem !important;
        height: 0.24rem;
        margin-right: 0.05rem;
        background: url(../assets/images/share2.png) no-repeat;
        background-position: 0 0 !important;

        &:hover {
          background: url(../assets/images/sprites.png) no-repeat;
          background-position: -6.22rem -0.64rem !important;
        }
      }
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: #5d979d !important;
    scrollbar-color: #5d979d #dedede;
    height: 0.3rem;
    // background-color: #cf2222;
    border-radius: 0.05rem;
  }

  .status {
    position: absolute;
    top: 1.6rem;
    left: -0.1rem;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.16rem;
    height: 0.62rem;
    background-color: #ffe8aa;
    font-size: 0.2rem;
    color: #000;

    &.ok {
      .ic-status {
        width: 0.24rem;
        height: 0.24rem;
        background-position: -6.64rem 0.04rem;
      }
    }

    &.fail {
      width: 0.24rem;
      height: 0.24rem;
      background-color: #6a6a6a;
      color: #fff;

      .ic-status {
        background-position: -6.9rem 0.04rem;
      }
    }

    &.ing {
      color: #fff;
      background-color: #cf2222;

      .ic-status {
        width: 0.19rem;
        height: 0.19rem;
        margin-right: 0.05rem;
        border: 0.01rem solid #fff;
        border-radius: 50%;
      }
    }
  }

  .fade-ing {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 3.1rem;
    height: 4.4rem;
    background: rgba(0, 0, 0, 0.7);
  }

  .fade-fail {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 3.1rem;
    height: 4.4rem;
    background: rgba(0, 0, 0, 0.4);
  }
}

.search-icon {
  width: 0.28rem;
  height: 0.28rem;
  display: block;
  background: url(../assets/images/search.png) no-repeat;
  background-size: 0.28rem auto;
  margin-left: 0.15rem;
}

.in-line {
  width: 1121px;
  height: 1px;
  background-color: #8b9ca0;
  margin: 0 auto;
}
</style>
