<template>
  <div class="the-scrollbar" ref="el" @mouseenter="onEnter" @mouseleave="onLeave">
    <div ref="wrap" class="the-scrollbar-wrap" :style="wrapStyle" @scroll="onWrapScroll">
      <slot></slot>
    </div>
    <div class="the-scrollbar-track" ref="track">
      <div class="the-scrollbar-thumb" ref="thumbY" :style="thumbStyle.y" @mousedown="onThumbTouchStart" @touchstart="onThumbTouchStart"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollbarSize: (() => {
        const el = document.createElement("div");
        el.style.width = "100px";
        el.style.height = "100px";
        el.style.overflow = "scroll";
        document.body.appendChild(el);
        const width = el.offsetWidth - el.clientWidth;
        el.remove();
        return width;
      })(),
      wrapStyle: {
        height: "",
        width: ""
      },
      thumbStyle: {
        y: {
          width: "",
          height: "",
          top: "",
          right: "",
          transform: "",
          borderRadius: "",
          backgroundColor: "rgba(147, 147, 153, 0.45)"
        }
      },
      showThumb: true
    };
  },
  mounted() {
    this.updateWrapStyle();
    this.updateThumbStyle();
    this.$refs.wrap && this.$refs.wrap.addEventListener("scroll", this.updateThumbStyle);
  },
  beforeDestroy() {
    this.$refs.wrap && this.$refs.wrap.removeEventListener("scroll", this.updateThumbStyle);
  },
  methods: {
    updateWrapStyle() {
      const parent = this.$refs.el.parentNode;
      parent.style.overflow = "hidden";
      const css = getComputedStyle(parent);
      this.wrapStyle.width = `calc(100% + ${this.scrollbarSize}px + ${css.borderLeftWidth} + ${css.borderRightWidth})`;
      this.wrapStyle.height = `calc(100% + ${this.scrollbarSize}px + ${css.borderTopWidth} + ${css.borderBottomWidth})`;
    },
    updateThumbStyle() {
      const wrapEl = this.$refs.wrap;
      if (wrapEl) {
        let height = (wrapEl.clientHeight / wrapEl.scrollHeight) * 100;
        if (height >= 100) height = 8;
        this.thumbStyle.y.height = height + "%";
        this.thumbStyle.y.transform = `translate3d(0, ${wrapEl.scrollTop / wrapEl.scrollHeight * wrapEl.clientHeight}px, 0)`;
      }
    },
    onEnter() {
      this.updateThumbStyle();
    },
    onLeave() {
      // No operation needed
    },
    onThumbTouchStart(event) {
      event.preventDefault();
      const startY = event.touches ? event.touches[0].clientY : event.clientY;
      const startScrollTop = this.$refs.wrap.scrollTop;
      const onTouchMove = (event) => {
        const offsetY = (event.touches ? event.touches[0].clientY : event.clientY) - startY;
        const wrapEl = this.$refs.wrap;
        wrapEl.scrollTop = startScrollTop + offsetY / wrapEl.clientHeight * wrapEl.scrollHeight;
        this.updateThumbStyle();
      };
      const onTouchEnd = () => {
        document.removeEventListener("mousemove", onTouchMove);
        document.removeEventListener("mouseup", onTouchEnd);
        document.removeEventListener("touchmove", onTouchMove);
        document.removeEventListener("touchend", onTouchEnd);
      };
      document.addEventListener("mousemove", onTouchMove);
      document.addEventListener("mouseup", onTouchEnd);
      document.addEventListener("touchmove", onTouchMove);
      document.addEventListener("touchend", onTouchEnd);
    },
    onWrapScroll() {
      this.updateThumbStyle();
    }
  }
};
</script>

<style lang="less">
.the-scrollbar {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .the-scrollbar-wrap {
    overflow: auto; // 修改为 auto
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .the-scrollbar-track {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 17px; // 轨道宽度
    // background-color: #f5f5f5; // 轨道背景色
    border-radius: 5px; // 轨道圆角
    -webkit-box-shadow: inset 0 0 6px rgb(173, 231, 248);
    background-color: rgba(235, 243, 237);
    border: solid 1px #7ab9cd;
    opacity: 0.8;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  .the-scrollbar-thumb {
    position: absolute;
    top: 0;
    right: 0;
    height: 67px;
    width: 15px; // 滑块宽度
    // background-color: rgba(0, 0, 0, 0.2); // 滑块颜色
    border-radius: 5px; // 滑块圆角
    cursor: pointer;
    background-color: #bbd7cd;
    border-radius: 4px;
    border: solid 1px #7ab9cd;
  }
}
</style>
