<template>
  <div>
    <div class="loading animate__animated animate__fadeOut" v-if="visible">
      <div>
        <div class="pic"></div>
        <div>加载中...</div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/loading.less';
export default {
  name: 'Loading',
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
